export const getAuthToken = (): string | null => {
    const auth = localStorage.getItem('auth');

    if (!auth) {
        return null;
    }

    const { access_token } = JSON.parse(auth);

    return access_token;
}

const isWithAuth = async() => {
    const access_token = getAuthToken();

    if (!access_token) {
        return false;
    }

    const request = new Request('api/auth/check', {
        method: 'GET',
        headers: new Headers({ 'Authorization': `Bearer ${access_token}` }),
    });

    const response = await fetch(request);

    return response.status !== 401;
}

export const authProvider = {
    login: ({ username, password }: { username: string, password: string }) =>  {
        const request = new Request('api/auth/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(auth => {
                localStorage.setItem('auth', JSON.stringify(auth));
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkError: () => Promise.resolve(),
    checkAuth: async() => await isWithAuth()
        ? Promise.resolve()
        : Promise.reject({ redirectTo: '/login' }),
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () => Promise.resolve({ id: '1' }),
    getPermissions: () => Promise.resolve([]),
};
