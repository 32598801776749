import React from 'react';
import { Admin, Resource, ShowGuesser } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';

import crudProvider from '@fusionworks/ra-data-nest-crud';

import { ClericCreate, ClericEdit, ClericList } from 'components/Cleric';
import { SermonCreate, SermonEdit, SermonList } from 'components/Sermon';
import { NewspaperCreate, NewspaperEdit, NewspaperList } from 'components/Newspaper';
import { EventTypeCreate, EventTypeEdit, EventTypeList } from 'components/EventType';

import { authProvider } from './utils/authProvider';
import { httpClient } from './utils/httpClient';
import './App.css';

const dataProvider = crudProvider('api', httpClient);
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

export const App = () => {
  return (
    <Admin
        title="Администрирования. Храм священномученика Антипы"
        dataProvider={dataProvider}
        authProvider={authProvider}
        i18nProvider={i18nProvider}
    >
      <Resource
          name="cleric"
          list={ClericList}
          create={ClericCreate}
          edit={ClericEdit}
          show={ShowGuesser}
          options={{ label: 'Духовенство' }}
      />
      <Resource
          name="sermon"
          list={SermonList}
          create={SermonCreate}
          edit={SermonEdit}
          show={ShowGuesser}
          options={{ label: 'Проповеди' }}
      />
      <Resource
          name="newspaper"
          list={NewspaperList}
          create={NewspaperCreate}
          edit={NewspaperEdit}
          show={ShowGuesser}
          options={{ label: 'Газета' }}
      />
      <Resource
          name="event_type"
          list={EventTypeList}
          create={EventTypeCreate}
          edit={EventTypeEdit}
          show={ShowGuesser}
          options={{ label: 'Типы событий' }}
      />
    </Admin>
  );
}
