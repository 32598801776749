import { Dispatch, SetStateAction } from 'react';
import { Record as RaRecord } from 'ra-core/esm/types';

import { uploadFile } from 'utils/uploadFile';

export const useTransformData = (setIsDataUploading: Dispatch<SetStateAction<boolean>>) =>
    async(data: RaRecord): Promise<RaRecord> => {
        const { file, cleric, newfile } = data;
        setIsDataUploading(true);

        if (typeof file !== 'string') {
            data.file = await uploadFile(file, 'sermons/audio');
        }

        if (newfile) {
            data.file = await uploadFile(newfile, 'sermons/audio');
        }

        const clericId = cleric.id;
        data.cleric = {
            id: clericId,
        }

        setIsDataUploading(false);

        return data;
    }
