import React, { FC } from 'react';
import {
    required,
    TextInput,
    TabbedForm,
    FormTab,
    FileField,
    ImageField,
    DateInput,
    NumberInput,
    Loading,
} from 'react-admin';

import { TextEditor } from 'components/TextEditor';
import { ImageInput } from 'components/ImageInput';

import { NewspaperFormProps } from './@types';
import { FileInput } from '../../FileInput';

const validateRequired = (isEdit?: boolean) => {
    if (!isEdit) return required();
};

export const NewspaperForm: FC<NewspaperFormProps> = (props) => {
    const { edit, saving, isDataUploading } = props;

    if (saving || isDataUploading) return <Loading loadingPrimary="Сохранение" />;

    return (
        <TabbedForm
            {...props}
        >
            <FormTab label="Основные данные">
                <TextInput
                    source="title"
                    validate={validateRequired()}
                    title="Заголовок"
                    label="Заголовок"
                    placeholder="Заголовок"
                    fullWidth
                />
                <NumberInput
                    source="serialNumber"
                    validate={validateRequired()}
                    title="Порядковый номер"
                    label="Порядковый номер"
                    placeholder="Порядковый номер"
                />
                <DateInput
                    source="date"
                    label="Дата"
                    placeholder="Дата"
                    validate={validateRequired()}
                />
            </FormTab>
            <FormTab label="Описание">
                <TextEditor
                    source="description"
                    title="Описание"
                    label=""
                    placeholder="Описание"
                />
            </FormTab>
            <FormTab label="Файлы">
                {
                    edit && (
                        <FileField
                            source="link"
                            title="link"
                            label="Газета"
                        />
                    )
                }
                <FileInput
                    source="link"
                    label={`${edit ? 'Выбрать новый файл газеты' : 'Файл газеты'} (PDF)`}
                    validate={validateRequired(edit)}
                    fieldProps={{
                        source: 'src',
                        title: 'file',
                        resource: 'newspaper',
                    }}
                    isEditMode={edit}
                />
                {
                    edit && (
                        <ImageField
                            source="poster"
                            label="Постер"
                        />
                    )
                }
                <ImageInput
                    source="poster"
                    label={`${edit ? 'Выбрать новый постер' : 'Постер'}. Необходимый размер: 718x956px`}
                    validate={validateRequired(edit)}
                    fieldProps={{
                        source: 'src',
                        title: 'title'
                    }}
                    isEditMode={edit}
                />
            </FormTab>
        </TabbedForm>
    );
};
