import { useEffect, SetStateAction, Dispatch } from 'react';

import { getEventTypes } from 'api/EventTypes';

import { EventType } from '../../@types';

export const useEventTypes = (setEventTypes: Dispatch<SetStateAction<EventType[]>>) => {
    useEffect(() => {
        (async() => {
            const eventTypes = await getEventTypes();

            setEventTypes(eventTypes);
        })();
    }, [setEventTypes]);
}
