import React, { FC } from 'react';
import Modal from '@mui/material/Modal';
import { grey } from '@mui/material/colors';
import CancelIcon from '@mui/icons-material/Cancel';
import Typography from '@mui/material/Typography';

import { cnSuccessSaveModal } from './SuccessSaveModal.cn';
import { SuccessModalProps, ImageType } from './@types';
import { ImageUrl } from './SuccessSaveModal.const';

import './SuccessSaveModal.css';

export const SuccessSaveModal: FC<SuccessModalProps> = (props) => {
    const {
        isOpen,
        imageType = ImageType.MATROSKIN,
        onClose,
        className,
        children,
    } = props;

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            className={cnSuccessSaveModal('Modal', [className])}
        >
            <div className={cnSuccessSaveModal('Body')}>
                <div className={cnSuccessSaveModal('Content')}>
                    {
                        children || (
                            <>
                                <div className={cnSuccessSaveModal('ContentImage')}>
                                    <img src={ImageUrl[imageType]} alt="" />
                                </div>
                                <Typography variant="h5" component="h2">
                                    Ура! Всё успешно сохранено!
                                </Typography>
                            </>
                        )
                    }
                </div>
                <div className={cnSuccessSaveModal('Close')} onClick={onClose}>
                    <CancelIcon sx={{ color: grey['A700'], fontSize: 30}} />
                </div>
            </div>
        </Modal>
    );
}

