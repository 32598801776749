import { IClassNameProps } from '@bem-react/core';

export enum ImageType {
    MATROSKIN = 'MATROSKIN',
    CHEBURASHKA = 'CHEBURASHKA',
    WINNIE_POOH = 'WINNIE_POOH'
}

export interface SuccessModalProps extends IClassNameProps {
    isOpen: boolean;
    onClose: VoidFunction;
    imageType?: ImageType;
}
