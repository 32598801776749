import React, { FC, useCallback, useState } from 'react';
import {
    Create,
    CreateProps,
    useRedirect, useRefresh,
} from 'react-admin';

import {
    ImageType,
    SuccessSaveModal as SuccessSaveModalBase,
    withTypeNewspaper,
} from 'components/SuccessSaveModal';

import { NewspaperForm } from '../Form';

import { useTransformData } from '../hooks/useTransformData';

const SuccessSaveModal = withTypeNewspaper(SuccessSaveModalBase);

export const NewspaperCreate: FC<CreateProps> = props => {
    const [isDataUploading, setIsDataUploading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onClose = useCallback(() => {
        setIsOpen(false);
        redirect('/newspaper');
        refresh();
    }, [redirect, refresh]);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, []);
    return (
        <>
            <Create
                {...props}
                onSuccess={onOpen}
                // @ts-ignore
                transform={useTransformData(setIsDataUploading)}
                title="Добавить новый выпуск газеты"
            >
                <NewspaperForm redirect="show" isDataUploading={isDataUploading} />
            </Create>
            <SuccessSaveModal
                isOpen={isOpen}
                onClose={onClose}
                type="newspaper"
                imageType={ImageType.CHEBURASHKA}
            />
        </>
    );
}
