import React, { FC, useCallback, useState } from 'react';
import {
    Create,
    CreateProps, useRedirect, useRefresh,
} from 'react-admin';

import { EventTypeForm } from '../Form';
import { ImageType, SuccessSaveModal } from '../../SuccessSaveModal';

export const EventTypeCreate: FC<CreateProps> = props => {
    const [isOpen, setIsOpen] = useState(false);
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onClose = useCallback(() => {
        setIsOpen(false);
        redirect('/event_type');
        refresh();
    }, [redirect, refresh]);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <>
            <Create
                {...props}
                onSuccess={onOpen}
                title="Добавить новое событие календаря"
            >
                <EventTypeForm redirect="show" />
            </Create>
            <SuccessSaveModal
                isOpen={isOpen}
                onClose={onClose}
                imageType={ImageType.WINNIE_POOH}
            />
        </>
    )
};
