import React, { FC } from 'react';
import {
    FileField,
    FileInput as FileInputBase,
} from 'react-admin';

import { FileInputProps } from './@types';
import { useStyles } from './FileInput.styles';

export const FileInput: FC<FileInputProps> = (props) => {
    const {
        isEditMode,
        fieldProps,
        source,
    } = props;

    const inputSource = isEditMode ? `new${source}` : source;

    return (
        <FileInputBase
            placeholder="Перетащите файл в зону или нажмите на нее для выбора"
            classes={useStyles()}
            {...props}
            source={inputSource}
        >
            <FileField {...fieldProps}/>
        </FileInputBase>
    )
}
