import React, { FC } from 'react';
import {
    required,
    TextInput,
    TabbedForm,
    FormTab,
    SelectInput,
    ReferenceInput,
    DateInput,
    FileField,
    Loading,
} from 'react-admin';

import { FileInput } from 'components/FileInput';

import { SermonFormProps } from './@types';
import { Cleric } from '../../Cleric/@types';

const validateRequired = (isEdit?: boolean) => {
    if (!isEdit) return required();
};

export const SermonForm: FC<SermonFormProps> = (props) => {
    const { edit, saving, isDataUploading } = props;

    if (saving || isDataUploading) return <Loading loadingPrimary="Сохранение" />;

    return (
        <TabbedForm {...props}>
            <FormTab label="Основные данные">
                {
                    (
                        <ReferenceInput
                            label="Священнослужитель"
                            source="cleric"
                            reference="cleric"
                            parse={(id: number) => {
                                return { id };
                            }}
                            format={(cleric?: Cleric) => {
                                if (cleric) {
                                    return cleric.id;
                                }
                            }}
                        >
                            <SelectInput
                                optionText={(cleric: Cleric) => `${cleric.post} ${cleric.firstName} ${cleric.lastName}`}
                                validate={validateRequired()}
                            />
                        </ReferenceInput>
                    )
                }
                <TextInput
                    source="title"
                    label="Заголовок"
                    placeholder="Заголовок"
                    validate={validateRequired()}
                    fullWidth
                />
                <DateInput
                    source="date"
                    label="Дата"
                    placeholder="Дата"
                    validate={validateRequired()}
                />
            </FormTab>
            <FormTab label="Файл аудиозаписи">
                {
                    edit && (
                        <FileField
                            source="file"
                            title="file"
                            label="Загруженная аудиозапись проповеди"
                        />
                    )
                }
                <FileInput
                    source="file"
                    label={edit ? 'Выбрать новый файл аудиозаписи' : 'Файл аудиозаписи'}
                    validate={validateRequired(edit)}
                    fieldProps={{
                        source: 'src',
                        title: 'file',
                        resource: 'sermon',
                    }}
                    isEditMode={edit}
                />
            </FormTab>
        </TabbedForm>
    );
};
