import React, { FC, useCallback, useState } from 'react';
import {
    Edit,
    EditProps, useRedirect, useRefresh,
} from 'react-admin';

import { EventTypeForm } from '../Form';
import { EventType } from '../@types';
import { ImageType, SuccessSaveModal } from '../../SuccessSaveModal';

const ClericEditTitle =  ({ record }: { record?: EventType }) => (
    <>
        Редактировать:
        {' '}
        {record?.title}
    </>
);

export const EventTypeEdit: FC<EditProps> = props => {
    const [isOpen, setIsOpen] = useState(false);
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onClose = useCallback(() => {
        setIsOpen(false);
        redirect('/event_type');
        refresh();
    }, [redirect, refresh]);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <>
            <Edit
                {...props}
                onSuccess={onOpen}
                mutationMode="pessimistic"
                title={<ClericEditTitle />}
            >
                <EventTypeForm redirect="list" edit />
            </Edit>
            <SuccessSaveModal
                isOpen={isOpen}
                onClose={onClose}
                imageType={ImageType.WINNIE_POOH}
            />
        </>
    )
};
