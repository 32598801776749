export const uploadFile = async(file: FileInputValue, path: string) => {
    const form = new FormData();
    let body = null;

    form.append('file', file.rawFile);
    form.append('key', path);

    const { access_token } = JSON.parse(localStorage.getItem('auth') as string);

    const headers = new Headers();
    headers.set('Authorization', `Bearer ${access_token}`)

    const response = await fetch('api/file/upload', {
        method: 'POST',
        body: form,
        headers,
    });

    if (!response.ok) {
        return null;
    }

    try {
        body = await response.json();
    } catch (e) {
        return null;
    }

    return body.url;
}
