import React, { FC, useCallback, useState } from 'react';
import { Edit, EditProps, useRedirect, useRefresh } from 'react-admin';

import {
    SuccessSaveModal as SuccessSaveModalBase,
    withTypeSermon,
    ImageType,
} from 'components/SuccessSaveModal';

import { SermonForm } from '../Form';
import { Sermon } from '../@types';
import { useTransformData } from '../hooks/useTransformData';

const SermonEditTitle =  ({ record }: { record?: Sermon }) => (<>Редактировать: {record?.title}</>);

const SuccessSaveModal = withTypeSermon(SuccessSaveModalBase);

export const SermonEdit: FC<EditProps> = props => {
    const [isDataUploading, setIsDataUploading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onClose = useCallback(() => {
        setIsOpen(false);
        redirect('/sermon');
        refresh();
    }, [redirect, refresh]);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <>
            <Edit
                {...props}
                title={<SermonEditTitle />}
                mutationMode="pessimistic"
                onSuccess={onOpen}
                // @ts-ignore
                transform={useTransformData(setIsDataUploading)}
            >
                <SermonForm edit isDataUploading={isDataUploading} />
            </Edit>
            <SuccessSaveModal
                isOpen={isOpen}
                onClose={onClose}
                imageType={ImageType.MATROSKIN}
            />
        </>
    )
};
