import React, { FC, useMemo, useState } from 'react';
import {
    required,
    TextInput,
    SimpleForm,
    Loading,
    SelectInput,
    BooleanInput,
} from 'react-admin';

import { cnEventType } from '../EventType.cn';

import {
    EventTypeFormProps,
    EventTypeFormColorChoice,
} from './@types';
import { useColorChoices } from './hooks/useColorChoices';
import { useEventTypes } from './hooks/useEventTypes';

import './EventType-Form.css';
import { EventType } from '../@types';

const validateRequired = required();

export const EventTypeForm: FC<EventTypeFormProps> = (props) => {
    const { saving } = props;

    const [colorChoices, setColorChoices] = useState<EventTypeFormColorChoice[]>([]);
    const [eventTypes, setEventTypes] = useState<EventType[]>([]);

    useColorChoices(setColorChoices, eventTypes);
    useEventTypes(setEventTypes);

    const ColorChoice = useMemo(() => ({ background, eventType }: EventTypeFormColorChoice) => {
        return (
            <div className={cnEventType('ColorChoice')}>
                <div
                    style={{ backgroundColor: background }}
                    className={cnEventType('ColorChoiceView')}
                />
                {
                    eventType && (
                        <div className={cnEventType('ColorChoiceTitle')}>{eventType.title}</div>
                    )
                }
            </div>
        )
    }, []);


    if (saving) return <Loading loadingPrimary="Сохранение" />;

    return (
        <SimpleForm {...props}>
            <TextInput
                source="title"
                validate={validateRequired}
                title="Название"
                label="Название"
                placeholder="Название"
            />
            <SelectInput
                source="colorId"
                title="Цвет"
                label="Цвет"
                validate={validateRequired}
                choices={colorChoices}
                optionText={ColorChoice}
            />
            <BooleanInput
                source="checked"
                title="Включен по-умолчанию"
                label="Включен по-умолчанию"
            />
        </SimpleForm>
    );
};
