import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    dropZone: {
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
