import React, { FC } from "react";
import {
    List,
    Datagrid,
    TextField,
} from 'react-admin';

import { ClericListProps } from './@types';

export const ClericList: FC<ClericListProps> = (props) => {
    return (
        <List {...props} title="Духовенство">
            <Datagrid rowClick="edit">
                <TextField source="firstName" label="Имя" />
                <TextField source="lastName" label="Фамилия" />
                <TextField source="post" label="Чин" />
            </Datagrid>
        </List>
    )
}
