import { useEffect, SetStateAction, Dispatch } from 'react';

import { getEventColors } from 'api/EventColors';

import { EventTypeFormColorChoice } from '../@types';
import { EventType } from '../../@types';

export const useColorChoices = (
    setColorChoices: Dispatch<SetStateAction<EventTypeFormColorChoice[]>>,
    eventTypes: EventType[]
) => {
    useEffect(() => {
        (async() => {
            const eventColors = await getEventColors();

            setColorChoices(Object.entries(eventColors).map(([key, value]) => {
                const eventType = eventTypes.find(eventType => String(eventType.colorId) === key);
                return {
                    id: key,
                    ...value,
                    disabled: Boolean(eventType),
                    eventType
                };
            }))
        })();
    }, [setColorChoices, eventTypes]);
}
