import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { pink } from '@mui/material/colors';
import { withBemMod } from '@bem-react/core';

import { cnSuccessSaveModal } from '../SuccessSaveModal.cn';
import { ImageType, SuccessModalProps } from '../@types';
import { ImageUrl } from '../SuccessSaveModal.const';

export interface WithTypeSermon {
    type?: 'sermon';
}

export const withTypeSermon = withBemMod<WithTypeSermon, SuccessModalProps>(
    cnSuccessSaveModal(),
    { type: 'sermon' },
    (SuccessSaveModal): FC<WithTypeSermon & SuccessModalProps> =>
        (props) => {
            const { imageType = ImageType.MATROSKIN } = props;

            return (
                <SuccessSaveModal {...props}>
                    <div className={cnSuccessSaveModal('ContentImage')}>
                        <img src={ImageUrl[imageType]} alt="" />
                    </div>
                    <Typography sx={{ mt: 2 }} variant="h5" component="h2">
                        Ура! Всё успешно сохранено!
                    </Typography>
                    <Typography sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
                        Спасибо вам за помощь!
                        <FavoriteIcon sx={{ color: pink['A400'], ml: 1, fontSize: 30 }} />
                    </Typography>
                </SuccessSaveModal>
            )
        }
)


