import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { pink } from '@mui/material/colors';
import { withBemMod } from '@bem-react/core';

import { cnSuccessSaveModal } from '../SuccessSaveModal.cn';
import { ImageType, SuccessModalProps } from '../@types';
import { ImageUrl } from '../SuccessSaveModal.const';

export interface WithTypeNewspaper {
    type?: 'newspaper';
}

export const withTypeNewspaper = withBemMod<WithTypeNewspaper, SuccessModalProps>(
    cnSuccessSaveModal(),
    { type: 'newspaper' },
    (SuccessSaveModal): FC<WithTypeNewspaper & SuccessModalProps> =>
        (props) => {
            const { imageType = ImageType.MATROSKIN } = props;

            return (
                <SuccessSaveModal {...props}>
                    <div className={cnSuccessSaveModal('ContentImage')}>
                        <img src={ImageUrl[imageType]} alt="" />
                    </div>
                    <Typography sx={{ mt: 2, textAlign: 'center' }} variant="h5" component="h2" alignContent={'center'}>
                        Настя, спасибо тебе большое!
                        <FavoriteIcon sx={{ color: pink['A400'], ml: 1, fontSize: 30, verticalAlign: 'bottom' }} />
                    </Typography>
                    <Typography sx={{ mt: 2, display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        Новый выпуск самой прекрасной газеты успешно сохранен!
                    </Typography>
                    <Typography sx={{ mt: 1, display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        Теперь можно выдохнуть 🙂
                    </Typography>
                </SuccessSaveModal>
            )
        }
)
