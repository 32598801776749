import { useEffect, SetStateAction, Dispatch } from 'react';

import { getEventColors } from 'api/EventColors';

export const useEventColors = (setEventColors: Dispatch<SetStateAction<Record<string, EventColor>>>) => {
    useEffect(() => {
        (async() => {
            const eventColors = await getEventColors();

            setEventColors(eventColors);
        })();
    }, [setEventColors]);
}
