import React, { FC, useCallback, useState } from 'react';
import {
    Edit,
    EditProps, useRedirect, useRefresh,
} from 'react-admin';

import { ImageType, SuccessSaveModal } from 'components/SuccessSaveModal';

import { ClericForm } from '../Form';
import { Cleric } from '../@types';
import { useTransformData } from '../hooks/useTransformData';

const ClericEditTitle =  ({ record }: { record?: Cleric }) => (
    <>
        Редактировать:
        {' '}
        {record?.post} {record?.firstName} {record?.lastName}
    </>
);

export const ClericEdit: FC<EditProps> = props => {
    const [isDataUploading, setIsDataUploading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onClose = useCallback(() => {
        setIsOpen(false);
        redirect('/cleric');
        refresh();
    }, [redirect, refresh]);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <>
            <Edit
                {...props}
                title={<ClericEditTitle />}
                mutationMode="pessimistic"
                onSuccess={onOpen}
                // @ts-ignore
                transform={useTransformData(setIsDataUploading)}
            >
                <ClericForm edit isDataUploading={isDataUploading} />
            </Edit>
            <SuccessSaveModal
                isOpen={isOpen}
                onClose={onClose}
                imageType={ImageType.WINNIE_POOH}
            />
        </>
    )
};
