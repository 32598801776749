import { fetchUtils } from 'react-admin';

import { getAuthToken } from './authProvider';

export const httpClient = (url: string, options: RequestInit = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const access_token = getAuthToken();

    (options.headers as Headers).set('Authorization', `Bearer ${access_token}`);

    return fetchUtils.fetchJson(url, options).catch((e) => {
        if (e.status === 401) {
            localStorage.removeItem('auth');
            window.location.pathname = '/';
        }

        return Promise.reject(e);
    })
};
