import React, { FC, useCallback, useState } from 'react';
import {
    Edit,
    EditProps,
    useRedirect,
    useRefresh,
} from 'react-admin';

import {
    ImageType,
    SuccessSaveModal as SuccessSaveModalBase,
    withTypeNewspaper,
} from 'components/SuccessSaveModal';

import { NewspaperForm } from '../Form';
import { Newspaper } from '../@types';

import { useTransformData } from '../hooks/useTransformData';

const NewspaperEditTitle =  ({ record }: { record?: Newspaper }) => (<>Редактировать: {record?.title}</>);

const SuccessSaveModal = withTypeNewspaper(SuccessSaveModalBase);

export const NewspaperEdit: FC<EditProps> = props => {
    const [isDataUploading, setIsDataUploading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const redirect = useRedirect();
    const refresh = useRefresh();

    const onClose = useCallback(() => {
        setIsOpen(false);
        redirect('/newspaper');
        refresh();
    }, [redirect, refresh]);

    const onOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <>
            <Edit
                {...props}
                onSuccess={onOpen}
                mutationMode="pessimistic"
                // @ts-ignore
                transform={useTransformData(setIsDataUploading)}
                title={<NewspaperEditTitle />}
            >
                <NewspaperForm redirect="list" edit isDataUploading={isDataUploading} />
            </Edit>
            <SuccessSaveModal
                isOpen={isOpen}
                onClose={onClose}
                imageType={ImageType.MATROSKIN}
            />
        </>
    )
};
