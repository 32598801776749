import React, { FC, useMemo, useState } from 'react';
import {
    List,
    SimpleList,
    Record as ReactAdminRecord,
} from 'react-admin';

import { cnEventType } from '../EventType.cn';

import { EventTypeListProps } from './@types';
import { useEventColors } from './hooks/useEventColors';

import './EventType-List.css';

export const EventTypeList: FC<EventTypeListProps> = (props) => {
    const [eventColors, setEventColors] = useState<Record<string, EventColor>>({});

    useEventColors(setEventColors);

    const SimpleListView = useMemo(() => ({ title, colorId }: ReactAdminRecord) => (
        <div className={cnEventType('SimpleListView')}>
            {title}
            <div
                className={cnEventType('ListColorView')}
                style={{ backgroundColor: eventColors[colorId]?.background }}
            />
        </div>
    ), [eventColors]);

    return (
        <List {...props} title="Типы событий календаря">
            <SimpleList
                linkType="edit"
                primaryText={SimpleListView}
            />
        </List>
    )
}
