import { Dispatch, SetStateAction } from 'react';
import { Record } from 'ra-core/esm/types';

import { uploadFile } from 'utils/uploadFile';

export const useTransformData = (setIsDataUploading: Dispatch<SetStateAction<boolean>>) =>
    async(data: Record) => {
        const { photo, newphoto } = data;
        setIsDataUploading(true);

        if (typeof photo !== 'string') {
            data.photo = await uploadFile(photo, 'cleric/photo');
        }

        if (newphoto) {
            data.photo = await uploadFile(newphoto, 'cleric/photo');
        }

        setIsDataUploading(false);

        return data;
    }
