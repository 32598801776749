import { Record } from 'ra-core/esm/types';

import { uploadFile } from 'utils/uploadFile';
import { Dispatch, SetStateAction } from 'react';

export const useTransformData = (setIsDataUploading: Dispatch<SetStateAction<boolean>>) =>
    async(data: Record) => {
        const { link, poster, newlink, newposter } = data;

        setIsDataUploading(true);

        if (typeof link !== 'string') {
            data.link = await uploadFile(link, 'newspaper/files');
        }

        if (typeof poster !== 'string') {
            data.poster = await uploadFile(poster, 'newspaper/poster');
        }

        if (newlink) {
            data.link = await uploadFile(newlink, 'newspaper/files');
        }

        if (newposter) {
            data.poster = await uploadFile(newposter, 'newspaper/poster');
        }

        setIsDataUploading(false);

        return data;
    }
