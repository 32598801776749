import React, { FC } from 'react';
import {
    ImageField,
    ImageInput as ImageInputBase,
} from 'react-admin';

import { ImageInputProps } from './@types';
import { useStyles } from './ImageInput.styles';

export const ImageInput: FC<ImageInputProps> = (props) => {
    const {
        fieldProps,
        isEditMode,
        source,
    } = props;

    const styles = useStyles();

    const inputSource = isEditMode ? `new${source}` : source;

    return (
        <ImageInputBase
            placeholder="Перетащите изображение в зону или нажмите на нее для выбора"
            classes={styles}
            {...props}
            source={inputSource}
        >
            <ImageField {...fieldProps} />
        </ImageInputBase>
    )
}
