import React, { FC } from 'react';
import {
    required,
    TextInput,
    TabbedForm,
    FormTab,
    Loading,
    ImageField,
} from 'react-admin';

import { TextEditor } from 'components/TextEditor';
import { ImageInput } from 'components/ImageInput';

import { ClericFormProps } from './@types';

const validateRequired = required();

export const ClericForm: FC<ClericFormProps> = (props) => {
    const { edit, saving, isDataUploading } = props;

    if (saving || isDataUploading) return <Loading loadingPrimary="Сохранение" />;

    return (
        <TabbedForm {...props}>
            <FormTab label="Основные данные">
                <TextInput source="firstName" validate={validateRequired} title="Имя" label="Имя" placeholder="Имя" />
                <TextInput source="lastName" validate={validateRequired} title="Фамилия" label="Фамилия" placeholder="Фамилия" />
                <TextInput source="post" validate={validateRequired} title="Чин" label="Чин" placeholder="Чин" />
                <TextInput source="postInTemple" validate={validateRequired} title="Должность в храме" label="Должность в храме" placeholder="Должность в храме" />
                <TextInput
                    source="fullBioLink"
                    validate={validateRequired}
                    title="Ссылка"
                    label="Ссылка"
                    helperText="Ссылка на страницу с полной биографией"
                    placeholder="Ссылка"
                />
            </FormTab>
            <FormTab label="Краткая биография">
                <TextEditor
                    source="shortBio"
                    validate={validateRequired}
                    title="Краткая биография"
                    label=""
                    placeholder="Краткая биография" />
            </FormTab>
            <FormTab label="Фото">
                {
                    edit && (
                        <ImageField
                            source="photo"
                            label="Фото"
                        />
                    )
                }
                <ImageInput
                    source="photo"
                    label={edit ? 'Выбрать новое фото' : 'Выбрать фото'}
                    fieldProps={{
                        source: 'src',
                        title: 'title'
                    }}
                    isEditMode={edit}
                />
            </FormTab>
        </TabbedForm>
    );
};
