import React, { FC } from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
} from 'react-admin';

import { NewspaperListProps } from './@types';

export const NewspaperList: FC<NewspaperListProps> = (props) => {
    return (
        <List {...props} title="Газета" sort={{ field: 'date', order: 'DESC' }}>
            <Datagrid rowClick="edit">
                <TextField source="title" label="Заголовок" />
                <DateField source="date" label="Дата" />
            </Datagrid>
        </List>
    )
}
